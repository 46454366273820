'use strict';

// Constructor
var Header = function() {
    var header = $('.header');
    var body = $('body');
    var menuOpen = $('.header__hamburguer');
    var menuLinks = $('.header__menu a');

    menuOpen.on('click', function(){
        header.toggleClass('-open');
        body.toggleClass('-hideOverflow');
    });

    menuLinks.on('click', function() {
        header.removeClass('-open');
        body.removeClass('-hideOverflow');
    });

    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
        ) {
        // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                scrollTop: target.offset().top + -50
                }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                console.log("shola");
                });
            }
        }
    });

    var targets = $('[data-target]');
    var contents = $('[data-content]');
    var autoSlide = setInterval(setNextSlide, 5000);

    function init() {
        targets.first().addClass('-active');
        contents.first().addClass('-active');
    }

    if (targets.length > 0) {
        targets.on('click', function(e) {
            clearInterval(autoSlide);
            e.preventDefault();
            var $this = $(this);
            var targettedContent = $this.data('target');

            targets.removeClass('-active');
            contents.removeClass('-active');

            $this.addClass('-active');
            contents.filter(function() {
                return $(this).data('content') === targettedContent;
            }).addClass('-active');

            autoSlide = setInterval(setNextSlide, 5000);
        });

        init();
    }

    function isActive(element) {
        return element.className.includes('-active');
    }

    function getCurrentSlideIndex() {
        var slidesArray = $.makeArray(targets);
        return slidesArray.findIndex(isActive);
    }

    function setNextSlide() {
        var currentSlideIndex = getCurrentSlideIndex();
        var newIndex = calculateNewIndex(currentSlideIndex, 'next');

        setActiveSlide(newIndex);            
    }

    function setActiveSlide(index) {            

        targets.removeClass('-active');
        contents.removeClass('-active');

        $(targets.get(index)).addClass('-active');
        $(contents.get(index)).addClass('-active');
    }

    function calculateNewIndex(index, dir) {
        var limit = targets.length - 1;
        var newIndex;

        if (dir === 'prev') {
            newIndex = index > 0 ? index - 1 : limit;
        } else {
            newIndex = index < limit ? index + 1 : 0;
        }

        return newIndex;
    }

    $('#agree').on('click', function() {
        $('#intro').removeClass('-visible');
        body.removeClass('-hideOverflow');
    });

    function detectLang() {
        var currentLang = $('html').attr('lang');
        var langClass = currentLang === 'es' ? '-esp' : '-eng';
        body.addClass(langClass);
    }

    detectLang();

    $('#lang-selector').on('change', function(e) {
        var selectedLang = e.target.value;
        var url = selectedLang === 'esp' ? '' : '/en';

        window.location.href = url;
    });
};

module.exports = Header;
